import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (environment.dev_login)
        return of(true);
    
    this.authService.signOn();
  }
}
