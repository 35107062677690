import { Component } from '@angular/core'

@Component({
  selector: 'cui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  getCurrentYear() {
    return new Date().getFullYear()
  }
}
