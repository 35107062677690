import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { items } from 'src/app/models/campaign-dropdown.model'
import { BasicValidator } from '../../validators/basic.validator'

@Component({
  selector: 'radio-dropdown',
  templateUrl: './radio-dropdown.component.html',
  styleUrls: ['./radio-dropdown.component.scss'],
})
export class RadioDropdownComponent implements OnInit {
  selectForm: FormGroup
  open: boolean = false
  handleSelected: items[] = []
  @Input() type: string
  @Input() otherTextHandle: string
  @Input() title: string
  private _handleSelect: number
  @Input()
  set handleSelect(handleSelect: number) {
    this._handleSelect = handleSelect
    this.handleSelected = []
    if (!handleSelect) {
      if (this.type == 'category') {
        for (let i = 1; i < 11; i++) {
          let item = {
            value: i,
            check: false,
          }
          this.handleSelected.push(item)
        }
      }
      this.onLoadHandle(this.handleSelected)
    } else {
      if (this.type == 'category') {
        for (let i = 1; i < 11; i++) {
          let item = {
            value: i,
            check: handleSelect == i ? true : false,
          }
          this.handleSelected.push(item)
        }
      }
      this.onLoadHandle(this.handleSelected)
    }
  }
  get handleSelect() {
    return this._handleSelect
  }
  @Output() dataSelect = new EventEmitter<number>()
  @Output() otherText = new EventEmitter<string>()
  selected: items[] = []
  status: boolean = false
  otherTextTemp: string = ''
  otherTextStatus: boolean = false
  constructor(private fb: FormBuilder, private readonly basicValidator: BasicValidator) {
    this.selectForm = new FormGroup({
      selected: new FormArray([]),
      otherCategory: new FormControl('', [this.basicValidator.emojiValidator(true)]),
    })
  }

  ngOnInit() {}

  onLoadHandle(selected: items[]) {
    this.selected = this.handleSelected.filter(c => c.check == true)
    this.selectedFA.clear()

    if (selected) {
      selected.forEach(el => {
        const newSelected: FormControl = new FormControl(el.check)
        this.selectedFA.push(newSelected)
      })
    }
    this.otherCategoryFC.setValue(this.otherTextHandle)
    this.otherTextTemp = this.otherTextHandle
    if (this.selected.length > 0) {
      this.dataSelect.emit(this.selected[0].value)
    } else {
      this.dataSelect.emit(null)
    }
  }

  onChange(i: number) {
    if (i == 9) {
      this.otherTextStatus = true
      if (this.otherTextTemp) {
        this.otherTextStatus = false
      }
      this.otherCategoryFC.setValidators([this.basicValidator.emojiValidator(true)])
      this.otherCategoryFC.updateValueAndValidity()
    } else {
      this.otherTextStatus = false
      this.otherCategoryFC.setValidators([])
      this.otherCategoryFC.updateValueAndValidity()
    }

    this.selectForm.value.selected.forEach((c, index) => {
      this.selectForm
        .get('selected')
        .get(`${index}`)
        .setValue(false)
    })
    this.selectForm
      .get('selected')
      .get(`${i}`)
      .setValue(true)
    this.selected = []
    this.selectForm.value.selected.forEach((c, index) => {
      let item: items = {
        value: index + 1,
        check: c,
      }
      this.selected.push(item)
    })

    this.selected = this.selected.filter(c => c.check == true)
    if (this.selected.length == 0) {
      this.selected.push({ value: i + 1, check: true })

      this.selectForm
        .get('selected')
        .get(`${i}`)
        .setValue(true)
    }
  }

  onCancel() {
    if (this.handleSelected.filter(c => c.check == true).length > 0) {
      this.open = false
      this.selected = []

      this.handleSelected.forEach((el, index) => {
        this.selectForm
          .get('selected')
          .get(`${index}`)
          .setValue(el.check)
        let item: items = {
          value: index + 1,
          check: el.check,
        }
        this.selected.push(item)
      })
      this.selected = this.selected.filter(c => c.check == true)

      this.otherTextTemp = this.otherTextHandle
      this.otherCategoryFC.patchValue(this.otherTextHandle)
      if (this.otherTextTemp == '' && this.selected[0].value == 10) {
        this.otherTextStatus = true
        this.otherCategoryFC.setValidators([this.basicValidator.emojiValidator(true)])
        this.otherCategoryFC.updateValueAndValidity()
      } else {
        this.otherCategoryFC.setValidators([])
        this.otherCategoryFC.updateValueAndValidity()
        this.otherTextStatus = false
      }
    } else {
      this.open = false
      this.onLoadHandle(this.handleSelected)
      this.otherTextStatus = false
    }
  }

  closeTag(event, items: items) {
    let temp = []
    this.selectForm.value.selected.forEach((c, index) => {
      let item: items = {
        value: index + 1,
        check: c,
      }
      temp.push(item)
    })

    temp = temp.filter(c => c.check == true)
    if (temp.length > 1) {
      this.selectForm
        .get('selected')
        .get(`${items.value - 1}`)
        .setValue(!items.check)
      this.selected = []
      this.selectForm.value.selected.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        this.selected.push(item)
      })
      this.selected = this.selected.filter(c => c.check == true)
    }
  }

  openSelectItem() {
    this.open = true
  }

  dataChanged(value) {
    this.otherTextTemp = value
    this.otherCategoryFC.patchValue(value)
    if (this.selected[0].value == 10) {
      if (this.otherTextTemp == '') {
        this.otherTextStatus = true
      } else {
        this.otherTextStatus = false
      }
    }
  }

  onSubmit() {
    this.open = false
    this.selected = []

    this.selectForm.value.selected.forEach((c, index) => {
      let item: items = {
        value: index + 1,
        check: c,
      }
      this.selected.push(item)
    })
    this.selected = this.selected.filter(c => c.check == true)
    if (this.selected.length > 0) {
      this.dataSelect.emit(this.selected[0].value)
      if (this.otherTextTemp != '' && this.selected[0].value == 10) {
        this.otherText.emit(this.otherTextTemp)
      }
    } else {
      this.dataSelect.emit(null)
    }
  }

  hasError(fieldName: string) {
    return this.selectForm.get(fieldName).invalid
  }

  get selectedFA(): FormArray {
    return this.selectForm.get('selected') as FormArray
  }
  get otherCategoryFC(): FormControl {
    return this.selectForm.get('otherCategory') as FormControl
  }
}
