import { Component } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'cui-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {

  constructor(
    public authService: AuthService,
    private notification: NzNotificationService,
  ) { }

  navigateToLucy() {
    const userInfo: User = JSON.parse(localStorage.getItem('user'))
    this.authService.redirectToLucy(userInfo.username).subscribe(() => { }, (error) => {
      this.notification.error('error', error.message)
    })
  }
}
