import { Component } from '@angular/core'

@Component({
  selector: 'layout-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LayoutLoginComponent {
  getCurrentYear() {
    return new Date().getFullYear()
  }
}
