import { Component } from '@angular/core'
import { User } from 'src/app/models/user.model'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'cui-topbar-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
})
export class TopbarProjectManagementComponent {
  user: User

  constructor(private authService: AuthService) {
    const userInfo = JSON.parse(localStorage.getItem('user'))
    this.user = userInfo
    if (!this.user || this.user.userId == -1) {
      this.authService.getCurrentProfile().subscribe(u => {
        this.user = u
      })
    }
  }
}
