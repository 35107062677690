import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'platformClass' })
export class PlatformClassPipe implements PipeTransform {

    transform(value: number): string {
        if (value == 1) {
            return 'bi bi-youtube';
        } else if (value == 2) {
            return 'bi bi-facebook';
        } else if (value == 3) {
            return 'bi bi-tiktok';
        } else if (value == 4) {
            return 'bi bi-globe';
        } else if (value == 5) {
            return 'bi bi-instagram';
        } else if (value == 6) {
            return 'bi bi-twitter';
        } else if (value == 7) {
            return 'bi bi-twitch';
        }
    }
    
}
