import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  username: string
  token: string

  constructor(
    public authService: AuthService,
    public router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.getCurrentProfile().pipe(
      map((user: User) => {
        if ((user.userId === -1) || !user) {
          this.authService.signOn()
        }
        return true;
      }, (error) => {
        console.log(error);
        this.authService.signOn();
      })
    );
  }
}
