import { NgModule } from '@angular/core';
import { DebounceClickDirective } from './debounce-click.directive';
import { ClickedOutsideDirective } from './clicked-outside.directive';

@NgModule({
  imports: [],
  declarations: [
    DebounceClickDirective,
    ClickedOutsideDirective
  ],
  exports: [
    DebounceClickDirective,
    ClickedOutsideDirective
  ],
})

export class DirectiveModule {

  static forRoot() {
    return {
      ngModule: DirectiveModule,
      providers: [],
    };
  }
} 