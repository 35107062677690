import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  form: FormGroup

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private notification: NzNotificationService,
  ) {
    this.form = fb.group({
      username: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required]],
    })
  }

  get username() {
    return this.form.controls.username
  }
  get password() {
    return this.form.controls.password
  }

  submitForm(): void {
    this.username.markAsDirty()
    this.username.updateValueAndValidity()
    this.password.markAsDirty()
    this.password.updateValueAndValidity()
    if (this.username.invalid || this.password.invalid) { return }
    this.authService.SignIn(this.username.value, this.password.value)
  }
}
