import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'dropdownType' })
export class DropdownTypePipe implements PipeTransform {
  transform(type: string, value: number): string {
    if (type == 'category') {
      if (value == 1) {
        return 'มือถือและอุปกรณ์'
      } else if (value == 2) {
        return 'คอมพิวเตอร์ และ Gadget'
      } else if (value == 3) {
        return 'ของใช้ภายในบ้าน'
      } else if (value == 4) {
        return 'ความงาม'
      } else if (value == 5) {
        return 'แม่และเด็ก'
      } else if (value == 6) {
        return 'อาหารและเครื่องดื่ม'
      } else if (value == 7) {
        return 'สุขภาพและกีฬา'
      } else if (value == 8) {
        return 'เครื่องแต่งกาย'
      } else if (value == 9) {
        return 'รถยนต์ จักรยานยนต์'
      } else if (value == 10) {
        return 'อื่น ๆ'
      } else {
        return ''
      }
    } else if (type == 'objective') {
      if (value == 1) {
        return 'Awareness'
      } else if (value == 2) {
        return 'Engagement'
      } else if (value == 3) {
        return 'Traffic'
      } else if (value == 4) {
        return 'Affiliate / Generate Sale'
      } else if (value == 5) {
        return 'อื่น ๆ'
      } else if (value == 6) {
        return 'Consideration'
      } else {
        return ''
      }
    } else if (type == 'preferred-channel') {
      if (value == 1) {
        return 'Youtube'
      } else if (value == 2) {
        return 'Facebook'
      } else if (value == 3) {
        return 'Tiktok'
      } else if (value == 4) {
        return 'Website'
      } else if (value == 5) {
        return 'Instagram'
      } else if (value == 6) {
        return 'Twitter'
      } else if (value == 7) {
        return 'Twitch'
      } else {
        return ''
      }
    } else if (type == 'type-of-content') {
      if (value == 1) {
        return 'Photo'
      } else if (value == 2) {
        return 'Photo Album'
      } else if (value == 3) {
        return 'Short Video'
      } else if (value == 4) {
        return 'Video'
      } else if (value == 5) {
        return 'Text'
      } else if (value == 6) {
        return 'Tweet'
      } else if (value == 7) {
        return 'Blog'
      } else if (value == 8) {
        return 'PR'
      } else {
        return ''
      }
    } else if (type == 'gender') {
      if (value == 1) {
        return 'ผู้หญิง'
      } else if (value == 2) {
        return 'ผู้ชาย'
      } else if (value == 3) {
        return 'ทุกเพศ'
      } else {
        return ''
      }
    } else if (type == 'age') {
      if (value == 1) {
        return '18-24 ปี'
      } else if (value == 2) {
        return '25-34 ปี'
      } else if (value == 3) {
        return '35-44 ปี'
      } else if (value == 4) {
        return '45-60 ปี'
      } else if (value == 5) {
        return '60 ปีขึ้นไป'
      } else {
        return ''
      }
    } else if (type == 'interest') {
      if (value == 1) {
        return 'รักสุขภาพ'
      } else if (value == 2) {
        return 'ชอบทำอาหาร'
      } else if (value == 3) {
        return 'ชอบแต่งบ้าน'
      } else if (value == 4) {
        return 'ความสวยงาม'
      } else if (value == 5) {
        return 'Tech & Gadget'
      } else if (value == 6) {
        return 'Outdoor'
      } else if (value == 7) {
        return 'การลงทุน'
      } else if (value == 8) {
        return 'ท่องเที่ยว'
      } else if (value == 9) {
        return 'ยานยนต์'
      } else if (value == 10) {
        return 'DIY'
      } else if (value == 11) {
        return 'อื่น ๆ'
      } else {
        return ''
      }
    } else if (type == 'size-of-influencer') {
      if (value == 1) {
        return 'Top Star Influencer'
      } else if (value == 2) {
        return 'Macro Influencer'
      } else if (value == 3) {
        return 'Micro Influencer'
      } else if (value == 4) {
        return 'Nano Influencer'
      } else if (value == 5) {
        return 'End User Influencer'
      } else {
        return ''
      }
    } else if (type == 'compensation') {
      if (value == 1) {
        return 'Free Sample'
      } else if (value == 2) {
        return 'Loan Product'
      } else if (value == 3) {
        return 'Repost Content'
      } else {
        return ''
      }
    }else if (type == 'follower') {
      if (value == 1) {
        return 'มากกว่า 1M'
      } else if (value == 2) {
        return '100K - 1M'
      } else if (value == 3) {
        return '10K - 100K'
      } else if (value == 4) {
        return '1K - 10K'
      } else if (value == 5) {
        return 'น้อยกว่า 1K'
      } else {
        return ''
      }
    }
  }
}
