import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Compensation, CompensationMap, items } from 'src/app/models/campaign-dropdown.model'

@Component({
  selector: 'checkbox-dropdown-compensation',
  templateUrl: './checkbox-dropdown-compensation.component.html',
  styleUrls: ['./checkbox-dropdown-compensation.component.scss'],
})
export class CheckboxDropdownCompensationComponent implements OnInit {
  selectForm: FormGroup
  open: boolean = false
  handleSelected: CompensationMap = {
    compensation: [],
    onTopCommission: null,
    budgetPerContent: null,
  }

  @Input() type: string
  @Input() title: string
  private _handleSelect: Compensation
  @Input()
  set handleSelect(compensationData: Compensation) {
    this._handleSelect = compensationData
    if (compensationData) {
      for (let i = 1; i < 4; i++) {
        let compensation = {
          value: i,
          check: compensationData.compensation == i ? true : false,
        }
        this.handleSelected.compensation.push(compensation)
      }
      this.handleSelected.budgetPerContent = compensationData.budgetPerContent
      this.handleSelected.onTopCommission = compensationData.onTopCommission

      this.onLoadHandle(this.handleSelected)
    }
  }
  get handleSelect() {
    return this._handleSelect
  }
  @Output() dataSelect = new EventEmitter<Compensation>()

  selected: items[] = []
  compensation: items[] = []
  status: boolean = false
  onTopCommission: number
  budgetPerContent: number
  constructor(private fb: FormBuilder) {
    this.selectForm = new FormGroup({
      compensation: new FormArray([]),
      onTopCommission: new FormControl('', [
        Validators.pattern('^[0-9]*([.][0-9]{1,2})?$'),
        Validators.max(2147483647),
      ]),
      budgetPerContent: new FormControl('', [
        Validators.pattern('^[0-9]*([.][0-9]{1,2})?$'),
        Validators.max(2147483647),
      ]),
    })
  }

  ngOnInit() {}

  onLoadHandle(selected: CompensationMap) {
    this.compensation = this.handleSelected.compensation.filter(c => c.check == true)
    // this.otherText = this.handleSelected.otherInterest;
    this.compensationFA.clear()
    if (selected) {
      selected.compensation.forEach(el => {
        const newGender: FormControl = new FormControl(el.check)
        this.compensationFA.push(newGender)
      })

      this.onTopCommissionFC.setValue(selected.onTopCommission)
      this.budgetPerContentFC.setValue(selected.budgetPerContent)
    }

    if (this.compensation.length < 1) {
      this.dataSelect.emit(this.handleSelect)
    }
  }

  onChange(i: number, type: string) {
    if (type == 'compensation') {
      this.selectForm.value.compensation.forEach((c, index) => {
        this.selectForm
          .get('compensation')
          .get(`${index}`)
          .setValue(false)
      })
      this.selectForm
        .get('compensation')
        .get(`${i}`)
        .setValue(true)
      this.compensation = []
      this.selectForm.value.compensation.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        this.compensation.push(item)
      })

      this.compensation = this.compensation.filter(c => c.check == true)
      if (this.compensation.length == 0) {
        this.compensation.push({ value: i + 1, check: true })

        this.selectForm
          .get('compensation')
          .get(`${i}`)
          .setValue(true)
      }
    }
  }

  onCancel() {
    if (this.handleSelected.compensation.filter(c => c.check == true).length > 0) {
      this.open = false
      this.compensation = []
      this.handleSelected.compensation.forEach((el, index) => {
        this.selectForm
          .get('compensation')
          .get(`${index}`)
          .setValue(el.check)
        let item: items = {
          value: el.value,
          check: el.check,
        }
        this.compensation.push(item)
      })
      this.compensation = this.compensation.filter(c => c.check == true)
      this.onTopCommissionFC.setValue(this.handleSelected.onTopCommission)
      this.budgetPerContentFC.setValue(this.handleSelected.budgetPerContent)
    } else {
      this.open = false
      this.onLoadHandle(this.handleSelected)
    }
  }

  closeTag(type: string, items: items) {
    if (type == 'compensation') {
      let temp = []
      this.selectForm.value.compensation.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        temp.push(item)
      })
      temp = temp.filter(c => c.check == true)
      if (temp.length > 1) {
        const isValue = element => element.value == items.value
        let index = this.handleSelected.compensation.findIndex(isValue)
        this.selectForm
          .get('compensation')
          .get(`${index}`)
          .setValue(!items.check)
        this.compensation = []
        this.selectForm.value.compensation.forEach((c, index) => {
          let item: items = {
            value: this.handleSelected.compensation[index].value,
            check: c,
          }
          this.compensation.push(item)
        })
        this.compensation = this.compensation.filter(c => c.check == true)
      }
    }
  }

  openSelectItem() {
    this.open = true
  }

  onSubmit() {
    this.open = false
    this.compensation = []
    let dataCompensation = []
    const onTopCommission = this.onTopCommissionFC.value
    const budgetPerContent = this.budgetPerContentFC.value
    this.selectForm.value.compensation.forEach((c, index) => {
      let item: items = {
        value: this.handleSelected.compensation[index].value,
        check: c,
      }
      this.compensation.push(item)
    })
    this.compensation = this.compensation.filter(c => c.check == true)
    this.compensation.forEach(item => {
      dataCompensation.push(item.value)
    })
    const compensation: Compensation = {
      compensation: dataCompensation[0],
      onTopCommission: onTopCommission == null ? 0 : onTopCommission,
      budgetPerContent: budgetPerContent == null ? 0 : budgetPerContent,
    }
    this.dataSelect.emit(compensation)

    this.handleSelected = {
      compensation: [],
      onTopCommission: null,
      budgetPerContent: null,
    }
  }

  get compensationFA(): FormArray {
    return this.selectForm.get('compensation') as FormArray
  }
  get budgetPerContentFC(): FormControl {
    return this.selectForm.get('budgetPerContent') as FormControl
  }
  get onTopCommissionFC(): FormControl {
    return this.selectForm.get('onTopCommission') as FormControl
  }

  hasError(fieldName: string) {
    return this.selectForm.get(fieldName).invalid
  }
}
