import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { BehaviorSubject, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { User } from '../models/user.model'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any
  private readonly api_url = `${environment.api_url}/api`
  private currentUser = new BehaviorSubject<User>(null)

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private notification: NzNotificationService,
    private readonly http: HttpClient,
  ) {}

  signOn() {
    localStorage.setItem('user', null)

    if (environment.dev_login) {
      return this.router.navigate(['/user/login'])
    } else {
      return (window.location.href = environment.lucy_url + '/user/login?request_from=ims')
    }
  }

  async SignIn(username: string, password: string) {
    try {
      this.getLoginData(username, password).subscribe(
        userData => {
          this.userData = userData
          if (this.userData.role === 'ADMIN') {
            this.router.navigate(['/brand-list'])
          } else {
            this.router.navigate(['dashboard/kpi'])
          }
          this.notification.success('Logged In', 'You have successfully logged!')
        },
        ({ error }) => {
          this.notification.error('Log In fail', 'Username or password is invalid!')
        },
      )
    } catch (error) {
      this.notification.error('Log In fail', 'Username or password is invalid!')
    }
  }

  getLoginData(username: string, password: string) {
    const body = new HttpParams().set('username', username).set('password', password)
    return this.http.post<User>(`${this.api_url}/login`, body).pipe(
      tap(user => {
        this.currentUser.next(user)
        if (user) {
          this.userData = user
          localStorage.setItem('user', JSON.stringify(this.userData))
        } else {
          localStorage.setItem('user', null)
        }
      }),
    )
  }

  redirectToLucy(username: string) {
    const params = new HttpParams().set('username', username.toString())
    return this.http
      .get<any>(`${this.api_url}/redirect/lucy`, { params })
      .pipe(
        tap(
          url => {
            const requestParam = []
            requestParam.push('access_token=' + encodeURIComponent(url.accessToken))
            window.open(
              `${environment.lucy_url}/api/internal/sign-in?${requestParam.join('&')}`,
              '_blank',
            )
          },
          ({ error }) => {
            this.notification.error('Log In fail', error)
          },
        ),
      )
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'))
    return user !== null
  }

  async SignOut() {
    this.logout()
      .pipe(catchError(err => of(true)))
      .subscribe(() => {
        window.location.href = `${environment.lucy_url}/api/internal/sign-out?request_from=ims`
      })
  }

  logout() {
    return this.http.post<void>(`${this.api_url}/logout`, null).pipe(
      tap(() => {
        localStorage.removeItem('user')
        this.currentUser.next(null)
      }),
    )
  }

  getCurrentProfile() {
    if (this.currentUser.value && this.currentUser.value.userId != -1) {
      return of(this.currentUser.value)
    }

    return this.http.get<User>(`${this.api_url}/users/profile`).pipe(
      tap(user => {
        this.currentUser.next(user)
        if (user) {
          this.userData = user
          const userInfo = JSON.parse(localStorage.getItem('user'))
          if (!userInfo) {
            localStorage.setItem('user', JSON.stringify(this.userData))
          }
        } else {
          localStorage.setItem('user', null)
        }
      }),
    )
  }

  getPartnerNameList(keyword: string) {
    const params = new HttpParams().set('q', keyword.toString())

    return this.http.get<any>(`${this.api_url}/users/aff`, { params })
  }

  getSession(): Observable<string> {
    return this.http.get(`${this.api_url}/users/session`, { responseType: 'text' })
  }
}
