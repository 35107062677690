import { NgModule } from '@angular/core'
import { AbsoluteValueFormatPipe } from './absolute-number.pipe'
import { PlatformClassPipe } from './platform-class.pipe'
import { MomentPipe } from './moment.pipe'
import { SafePipe } from './safe.pipe'
import { FormatPricePipe } from './format-price.pipe'
import { ShortNumberPipe } from './shortNumber.pipe'
import { DropdownTypePipe } from './dropdown-type.pipe'

@NgModule({
  imports: [],
  declarations: [
    AbsoluteValueFormatPipe,
    SafePipe,
    MomentPipe,
    PlatformClassPipe,
    FormatPricePipe,
    ShortNumberPipe,
    DropdownTypePipe,
  ],
  exports: [
    AbsoluteValueFormatPipe,
    SafePipe,
    MomentPipe,
    PlatformClassPipe,
    FormatPricePipe,
    ShortNumberPipe,
    DropdownTypePipe,
  ],
})
export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    }
  }
}
