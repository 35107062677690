import { Injectable } from '@angular/core'
import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms'

@Injectable({
    providedIn: 'root'
})
export class BasicValidator {

    constructor() { }

    urlValidator(require: boolean = false): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null =>  {
            const url: string = control.value
            if (!url && !require) {
                return null
            }

            if (!url && require) {
                return { required: true }
            }
            const regexp = /(^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/%/?#[\]@!\$&'\(\)\*\+,;=.]+$)|(^$)/g


            if (!regexp.test(url)) {
                return { invalidUrl: true }
            }

            try {
                decodeURI(url)
            } catch (err) {
                return { malformUrl: true }
            }
            return null
        }
    }

    productUrlValidator(require: boolean = false): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null =>  {
            const url: string = control.value
            if (!url && !require) {
                return null
            }

            if (!url && require) {
                return { required: true }
            }
            const regexp = /(^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/%/?#[\]@!\$&'\(\)\*\+,;=.]+$)|(^$)/g


            if (!regexp.test(url)) {
                return { invalidUrl: true }
            }

            try {
                decodeURI(url)
            } catch (err) {
                return { malformUrl: true }
            }
            return null
        }
    }

    emojiValidator(require: boolean = false) : ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null =>  {
            const name: string = control.value
            if (!name && !require) {
                return null
            }

            if (!name && require) {
                return { required: true }
            }

            const regexp = /(\u00a9|\u00ae|[\u2000-\u3039]|[\u3100-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/

            if (regexp.test(name)) {
                return { invalidName: true }
            }

            return null
        }
    }
}
