import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/components/LayoutComponents/Guard/auth.guard'
import { DevGuard } from 'src/app/components/LayoutComponents/Guard/dev.guard'

// layouts & notfound
import { LayoutLoginComponent } from 'src/app/layouts/Login/login.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'
import { NotFoundComponent } from 'src/app/pages/404.component'

// user
import { LoginComponent } from 'src/app/pages/user/login/login.component'
import { ForgotComponent } from 'src/app/pages/user/forgot/forgot.component'
import { BrandListComponent } from './pages/brand-list/brand-list.component'
import { environment } from 'src/environments/environment'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/kpi',
    pathMatch: 'full',
  },

  {
    path: 'user',
    component: LayoutLoginComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' },
        canActivate: [DevGuard],
      },
    ],
  },

  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'brand-list',
        component: BrandListComponent,
        data: { title: 'Brand List' },
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: '',
    component: LayoutLoginComponent,
    children: [
      {
        path: 'activate-user',
        loadChildren: 'src/app/pages/activate-user/activate-user.module#ActivateUserModule',
      },
    ],
    
  },

  {
    path: '',
    component: LayoutLoginComponent,
    children: [
      {
        path: 'export-influencer-keyword',
        loadChildren: 'src/app/pages/export-influencer-keyword/export-influencer-keyword.module#ExportInfluencerKeywordModule',
      },
    ],
    
  },

  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: 'src/app/pages/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: '',
        loadChildren: 'src/app/pages/config/config.module#ConfigModule',
      },
      {
        path: 'influencer-contact',
        loadChildren: 'src/app/pages/manage-contact/manage-contact.module#ManageContactModule',
      },

      {
        path: '',
        loadChildren: 'src/app/pages/all-campaigns/all-campaigns.module#AllCampaignsModule',
      },

      {
        path: '',
        loadChildren: 'src/app/pages/influencer-explorer/influencer-explorer.module#InfluencerExplorerModule',
      },

      // {
      //   path: 'antd',
      //   loadChildren: 'src/app/pages/antd/antd.module#AntdModule',
      // },
      // {
      //   path: 'apps',
      //   loadChildren: 'src/app/pages/apps/apps.module#AppsModule',
      // },
      // {
      //   path: 'blog',
      //   loadChildren: 'src/app/pages/blog/blog.module#BlogModule',
      // },
      // {
      //   path: 'youtube',
      //   loadChildren: 'src/app/pages/youtube/youtube.module#YoutubeModule',
      // },
      // {
      //   path: 'ecommerce',
      //   loadChildren: 'src/app/pages/ecommerce/ecommerce.module#EcommerceModule',
      // },
      // {
      //   path: 'pages',
      //   loadChildren: 'src/app/pages/pages/pages.module#PagesModule',
      // },
      // {
      //   path: 'github',
      //   loadChildren: 'src/app/pages/github/github.module#GithubModule',
      // },
      // {
      //   path: 'icons',
      //   loadChildren: 'src/app/pages/icons/icons.module#IconsModule',
      // },
      // {
      //   path: 'charts',
      //   loadChildren: 'src/app/pages/charts/charts.module#ChartsModule',
      // },
      // {
      //   path: 'layout',
      //   loadChildren: 'src/app/pages/layout/layout.module#LayoutModule',
      // },
      { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] },
    ],
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppPreloader,
    }),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  declarations: [LoginComponent, ForgotComponent, NotFoundComponent, BrandListComponent],
  exports: [RouterModule],
})
export class AppRoutingModule {}
