export class items {
  value: number
  check: boolean
}
export class TargetGroupMap {
  gender?: items[]
  ageList?: items[]
  interestList?: items[]
  otherInterest?: string
}
export class TargetGroup {
  gender: number
  ageList: number[]
  interestList: number[]
  otherInterest: string
}

export class Compensation {
  compensation: number
  onTopCommission: number
  budgetPerContent: number
}

export class CompensationMap {
  compensation: items[]
  onTopCommission: number
  budgetPerContent: number
}
