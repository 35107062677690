import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NzNotificationService } from 'ng-zorro-antd'
import { Brand } from 'src/app/models/brand.model';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {

  brandList: Brand[]
  brandListOfDisplayData: Brand[]
  sortNameDatabase = null
  sortValueDatabase = null
  searchValue = ''
  sortName: string | null = null
  sortValue: string | null = null

  constructor(
    private dashboardService: DashboardService,
    public router: Router,
    private notification: NzNotificationService,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.dashboardService
      .getBrandList()
      .subscribe(
        res => (
          (this.brandList = res),
          (this.brandListOfDisplayData = res)
        ), ({ error }) => {
          this.notification.error('error ', error.message)
          this.authService.SignOut()
        }
      )
  }

  reset(): void {
    this.searchValue = ''
    this.search()
  }

  sort(sortName: string, value: string): void {
    this.sortName = sortName
    this.sortValue = value
    this.search()
  }

  filterFunc = (item: { brandName: string; id: number }) => {
    const sValue = this.searchValue.toLowerCase()
    const store = item.brandName.toLowerCase()
    return store.indexOf(sValue) !== -1 || item.id.toString().indexOf(this.searchValue) !== -1
  }

  search(): void {
    const data = this.brandList.filter((item: { brandName: string; id: number }) =>
      this.filterFunc(item),
    )
    this.brandListOfDisplayData = data.sort((a, b) =>
      a.id > b.id
        ? 1
        : -1,
    )
  }

  onChange(value: string): void {
    this.search()
  }

  selectBrand(brandId: number, brandName: string): void {
    let userInfo = JSON.parse(localStorage.getItem('user'))
    if (!userInfo) {
      this.authService.getCurrentProfile().subscribe(u => {
        userInfo = u
      })
    }
    userInfo.brandId = brandId
    userInfo.brandName = brandName
    this.dashboardService.getBrandById(brandId).subscribe((res) => {
      userInfo.defaultStartDate = res.defaultStartDate
      localStorage.setItem('user', JSON.stringify(userInfo))
      this.router.navigate(['dashboard/kpi'])
    })
  }

}
