import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { items, TargetGroup, TargetGroupMap } from 'src/app/models/campaign-dropdown.model'
import { BasicValidator } from '../../validators/basic.validator'

@Component({
  selector: 'checkbox-dropdown-target-group',
  templateUrl: './checkbox-dropdown-target-group.component.html',
  styleUrls: ['./checkbox-dropdown-target-group.component.scss'],
})
export class CheckboxDropdownTargetGroupComponent implements OnInit {
  selectForm: FormGroup
  open: boolean = false
  handleSelected: TargetGroupMap = {
    gender: [],
    ageList: [],
    interestList: [],
    otherInterest: null,
  }

  @Input() type: string
  @Input() title: string
  private _handleSelect: TargetGroup
  @Input()
  set handleSelect(handleSelect: TargetGroup) {
    this._handleSelect = handleSelect
    if (handleSelect) {
      for (let i = 1; i < 4; i++) {
        let gender = {
          value: i,
          check: handleSelect.gender == i ? true : false,
        }
        this.handleSelected.gender.push(gender)
      }
      for (let i = 1; i < 6; i++) {
        let age = {
          value: i,
          check: handleSelect.ageList.includes(i) ? true : false,
        }
        this.handleSelected.ageList.push(age)
      }
      for (let i = 1; i < 12; i++) {
        let interestList = {
          value: i,
          check: handleSelect.interestList.includes(i) ? true : false,
        }
        this.handleSelected.interestList.push(interestList)
        if (i === 11) {
          this.handleSelected.otherInterest = handleSelect.otherInterest
        } else {
          this.handleSelected.otherInterest = null
        }
      }
      this.onLoadHandle(this.handleSelected)
    }
  }
  get handleSelect() {
    return this._handleSelect
  }
  @Output() dataSelect = new EventEmitter<TargetGroup>()

  selected: items[] = []
  gender: items[] = []
  age: items[] = []
  interestList: items[] = []
  status: boolean = false
  otherTextTemp: string = ''
  otherTextStatus: boolean = false
  genderRequrieStatus: boolean = false
  ageRequrieStatus: boolean = false
  interestRequrieStatus: boolean = false
  constructor(private fb: FormBuilder, private readonly basicValidator: BasicValidator) {
    this.selectForm = new FormGroup({
      gender: new FormArray([]),
      age: new FormArray([]),
      interest: new FormArray([]),
      otherInterest: new FormControl('', [this.basicValidator.emojiValidator(true)]),
    })
  }

  ngOnInit() {}

  onLoadHandle(selected: TargetGroupMap) {
    this.gender = this.handleSelected.gender.filter(c => c.check == true)
    this.age = this.handleSelected.ageList.filter(c => c.check == true)
    this.interestList = this.handleSelected.interestList.filter(c => c.check == true)
    this.otherInterestFC.setValue(this.handleSelected.otherInterest)
    this.otherTextTemp = this.handleSelected.otherInterest
    // const control = this.handleSelect.map(c => new FormControl(c.check));
    this.genderFA.clear()
    this.ageFA.clear()
    this.interestFA.clear()
    if (selected) {
      selected.gender.forEach(el => {
        const newGender: FormControl = new FormControl(el.check)
        this.genderFA.push(newGender)
      })
      selected.ageList.forEach(el => {
        const newAge: FormControl = new FormControl(el.check)
        this.ageFA.push(newAge)
      })
      selected.interestList.forEach(el => {
        const newAttention: FormControl = new FormControl(el.check)
        this.interestFA.push(newAttention)
      })
    }

    if (this.gender.length < 1 || this.age.length < 1 || this.interestList.length < 1) {
      this.dataSelect.emit(this.handleSelect)
    }
    this.checkSelected()
  }

  onChange(i: number, type: string) {
    if (type == 'gender') {
      this.selectForm.value.gender.forEach((c, index) => {
        this.selectForm
          .get('gender')
          .get(`${index}`)
          .setValue(false)
      })
      this.selectForm
        .get('gender')
        .get(`${i}`)
        .setValue(true)

      this.gender = []

      this.selectForm.value.gender.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        this.gender.push(item)
      })

      this.gender = this.gender.filter(c => c.check == true)
      this.checkSelected()
      if (this.gender.length == 0) {
        this.gender.push({ value: i + 1, check: true })

        this.selectForm
          .get('gender')
          .get(`${i}`)
          .setValue(true)
      }
    } else if (type == 'age') {
      this.age = []
      this.selectForm.value.age.forEach((c, index) => {
        let item: items = {
          value: this.handleSelected.ageList[index].value,
          check: c,
        }
        this.age.push(item)
      })
      this.age = this.age.filter(c => c.check == true)
      this.checkSelected()
      // if (this.age.length == 0) {
      //   this.age.push({ value: this.handleSelected.ageList[i].value, check: true })
      //   this.selectForm
      //     .get('age')
      //     .get(`${i}`)
      //     .setValue(true)
      // }
    } else if (type == 'interest') {
      this.interestList = []
      this.selectForm.value.interest.forEach((c, index) => {
        let item: items = {
          value: this.handleSelected.interestList[index].value,
          check: c,
        }
        this.interestList.push(item)
      })
      this.interestList = this.interestList.filter(c => c.check == true)
      this.checkSelected()
      // if (this.interestList.length == 0) {
      //   this.interestList.push({ value: this.handleSelected.interestList[i].value, check: true })
      //   this.selectForm
      //     .get('interest')
      //     .get(`${i}`)
      //     .setValue(true)
      // }
      if (this.interestList.some(item => item.value == 11)) {
        this.otherTextStatus = true
        if (this.otherTextTemp) {
          this.otherTextStatus = false
        }
        this.otherInterestFC.setValidators([this.basicValidator.emojiValidator(true)])
        this.otherInterestFC.updateValueAndValidity()
      } else {
        this.otherTextStatus = false
        this.otherInterestFC.setValidators([])
        this.otherInterestFC.updateValueAndValidity()
      }
    }
  }

  onCancel() {
    if (
      this.handleSelected.gender.filter(c => c.check == true).length > 0 &&
      this.handleSelected.ageList.filter(c => c.check == true).length > 0 &&
      this.handleSelected.interestList.filter(c => c.check == true).length > 0
    ) {
      this.open = false
      this.gender = []
      this.genderRequrieStatus = false
      this.ageRequrieStatus = false
      this.interestRequrieStatus = false
      this.handleSelected.gender.forEach((el, index) => {
        this.selectForm
          .get('gender')
          .get(`${index}`)
          .setValue(el.check)
        let item: items = {
          value: el.value,
          check: el.check,
        }
        this.gender.push(item)
      })
      this.gender = this.gender.filter(c => c.check == true)

      this.age = []
      this.handleSelected.ageList.forEach((el, index) => {
        this.selectForm
          .get('age')
          .get(`${index}`)
          .setValue(el.check)
        let item: items = {
          value: el.value,
          check: el.check,
        }
        this.age.push(item)
      })
      this.age = this.age.filter(c => c.check == true)

      this.interestList = []
      this.handleSelected.interestList.forEach((el, index) => {
        this.selectForm
          .get('interest')
          .get(`${index}`)
          .setValue(el.check)
        let item: items = {
          value: el.value,
          check: el.check,
        }
        this.interestList.push(item)
      })
      this.interestList = this.interestList.filter(c => c.check == true)
      this.otherInterestFC.setValue(this.handleSelected.otherInterest)
      this.otherTextTemp = this.handleSelected.otherInterest
      if (this.interestList.some(item => item.value == 11)) {
        this.otherTextStatus = true
        if (this.otherTextTemp) {
          this.otherTextStatus = false
        }
        this.otherInterestFC.setValidators([this.basicValidator.emojiValidator(true)])
        this.otherInterestFC.updateValueAndValidity()
      } else {
        this.otherInterestFC.setValidators([])
        this.otherInterestFC.updateValueAndValidity()
        this.otherTextStatus = false
      }
    } else {
      this.open = false
      this.onLoadHandle(this.handleSelected)
      this.otherTextStatus = false
    }
  }

  closeTag(type: string, items: items) {
    if (type == 'gender') {
      let temp = []
      this.selectForm.value.gender.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        temp.push(item)
      })
      temp = temp.filter(c => c.check == true)
      if (temp.length >= 1) {
        const isValue = element => element.value == items.value
        let index = this.handleSelected.gender.findIndex(isValue)
        this.selectForm
          .get('gender')
          .get(`${index}`)
          .setValue(!items.check)
        this.gender = []
        this.selectForm.value.gender.forEach((c, index) => {
          let item: items = {
            value: this.handleSelected.gender[index].value,
            check: c,
          }
          this.gender.push(item)
        })
        this.gender = this.gender.filter(c => c.check == true)
        this.checkSelected()
      }
    } else if (type == 'age') {
      let temp = []
      this.selectForm.value.age.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        temp.push(item)
      })
      temp = temp.filter(c => c.check == true)
      if (temp.length >= 1) {
        const isValue = element => element.value == items.value
        let index = this.handleSelected.ageList.findIndex(isValue)
        this.selectForm
          .get('age')
          .get(`${index}`)
          .setValue(!items.check)
        this.age = []
        this.selectForm.value.age.forEach((c, index) => {
          let item: items = {
            value: this.handleSelected.ageList[index].value,
            check: c,
          }
          this.age.push(item)
        })
        this.age = this.age.filter(c => c.check == true)
        this.checkSelected()
      }
    } else if (type == 'interest') {
      let temp = []
      this.selectForm.value.interest.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        temp.push(item)
      })
      temp = temp.filter(c => c.check == true)
      if (temp.length >= 1) {
        const isValue = element => element.value == items.value
        let index = this.handleSelected.interestList.findIndex(isValue)
        this.selectForm
          .get('interest')
          .get(`${index}`)
          .setValue(!items.check)
        this.interestList = []
        this.selectForm.value.interest.forEach((c, index) => {
          let item: items = {
            value: this.handleSelected.interestList[index].value,
            check: c,
          }
          this.interestList.push(item)
        })
        this.interestList = this.interestList.filter(c => c.check == true)
        this.checkSelected()
      }
      if (this.interestList.some(item => item.value == 11)) {
        this.otherTextStatus = true
        if (this.otherTextTemp) {
          this.otherTextStatus = false
        }
        this.otherInterestFC.setValidators([this.basicValidator.emojiValidator(true)])
        this.otherInterestFC.updateValueAndValidity()
      } else {
        this.otherInterestFC.setValidators([])
        this.otherInterestFC.updateValueAndValidity()
        this.otherTextStatus = false
      }
    }
  }

  openSelectItem() {
    this.open = true
    this.checkSelected()
  }

  dataChanged(value) {
    this.otherTextTemp = value
    this.otherInterestFC.patchValue(value)
    if (this.interestList.some(item => item.value == 11)) {
      if (this.otherTextTemp == '') {
        this.otherTextStatus = true
      } else {
        this.otherTextStatus = false
      }
    }
  }

  checkSelected() {
    if (this.age.length == 0) {
      this.ageRequrieStatus = true
    } else {
      this.ageRequrieStatus = false
    }
    if (this.interestList.length == 0) {
      this.interestRequrieStatus = true
    } else {
      this.interestRequrieStatus = false
    }
    if (this.gender.length == 0) {
      this.genderRequrieStatus = true
    } else {
      this.genderRequrieStatus = false
    }
  }

  onSubmit() {
    this.open = false

    this.gender = []
    const dataGender = []
    this.selectForm.value.gender.forEach((c, index) => {
      let item: items = {
        value: this.handleSelected.gender[index].value,
        check: c,
      }
      this.gender.push(item)
    })
    this.gender = this.gender.filter(c => c.check == true)
    this.gender.forEach(item => {
      dataGender.push(item.value)
    })
    // --------------------------------------------------------------------------------------------
    this.age = []
    const dataAge = []
    this.selectForm.value.age.forEach((c, index) => {
      let item: items = {
        value: this.handleSelected.ageList[index].value,
        check: c,
      }
      this.age.push(item)
    })
    this.age = this.age.filter(c => c.check == true)
    this.age.forEach(item => {
      dataAge.push(item.value)
    })
    // --------------------------------------------------------------------------------------------
    this.interestList = []
    const dataInterestList = []
    this.selectForm.value.interest.forEach((c, index) => {
      let item: items = {
        value: this.handleSelected.interestList[index].value,
        check: c,
      }
      this.interestList.push(item)
    })
    this.interestList = this.interestList.filter(c => c.check == true)
    this.interestList.forEach(item => {
      dataInterestList.push(item.value)
    })
    // --------------------------------------------------------------------------------------------

    const targetGroup = {
      gender: dataGender[0],
      ageList: dataAge,
      interestList: dataInterestList,
      otherInterest: null,
    }
    if (this.otherTextTemp != '' && this.interestList.some(item => item.value == 11)) {
      targetGroup.otherInterest = this.otherTextTemp
    }
    this.dataSelect.emit(targetGroup)

    this.handleSelected = {
      gender: [],
      ageList: [],
      interestList: [],
      otherInterest: null,
    }
  }

  get genderFA(): FormArray {
    return this.selectForm.get('gender') as FormArray
  }
  get ageFA(): FormArray {
    return this.selectForm.get('age') as FormArray
  }
  get interestFA(): FormArray {
    return this.selectForm.get('interest') as FormArray
  }
  get otherInterestFC(): FormControl {
    return this.selectForm.get('otherInterest') as FormControl
  }
}
