export const environment = {
  production: false,
  // api_url: 'http://localhost:8680',
  api_url: 'https://dev-ims.priceza.com',

  // web_url: 'http://localhost:4200',
  web_url: 'https://uat.shopfluence.co',

  lucy_url: 'https://uat-lucy.priceza.com',
  short_link_url: 'https://uat-raka.priceza.com.sg/r/',
  aff_url: 'https://uat-aff.priceza.com',
  dev_login: true,
}
