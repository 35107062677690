import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Brand } from '../models/brand.model'
import { InfluencerPerformance } from '../models/influencer-performance.model'
import { PageResponse } from '../models/page-response.model'
import { ContentInfo } from '../models/content-info.model'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly api_url = `${environment.api_url}/api`

  constructor(private readonly http: HttpClient) {}

  getBrandList() {
    return this.http.get<Brand[]>(`${this.api_url}/brand`)
  }

  getBrandById(brandId: number) {
    return this.http.get<Brand>(`${this.api_url}/brand/${brandId}`)
  }

  getKpiDashboard(brandId: number, startDate: string, endDate: string, campaignId: number) {
    const body = {
      startDate: startDate,
      endDate: endDate,
      campaignId: campaignId,
    }

    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
    }
    return this.http.post<any>(`${this.api_url}/dashboard/${brandId}`, body, httpOptions)
  }

  getPerformanceInfluencer(
    brandId: number,
    startDate: string,
    endDate: string,
    page: number,
    size: number,
    sortColumn: string,
    sortOrder: string,
    campaignId: number,
  ) {
    const body = {
      startDate: startDate,
      endDate: endDate,
      page: page,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      campaignId: campaignId,
    }
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
    }
    return this.http.post<PageResponse<InfluencerPerformance>>(
      `${this.api_url}/dashboard/performance/${brandId}`,
      body,
      httpOptions,
    )
  }

  getContentInfo(partnerId: number, contentIdList: number[], startDate, endDate) {
    const body = {
      partnerId: partnerId,
      contentIdList: contentIdList,
      startDate: startDate,
      endDate: endDate
    }

    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
    }
    return this.http.post<ContentInfo[]>(
      `${this.api_url}/dashboard/performance/content-info`,
      body,
      httpOptions,
    )
  }

  exportConversionReport(
    brandId: number,
    campaignId: number,
    startDate: string,
    endDate: string,
  ): Observable<Blob> {
    const params = new HttpParams()
      .set('brandId', brandId.toString())
      .set('campaignId', campaignId.toString())
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())

    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.api_url}/download/conversion-report`, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'blob',
      params,
    })
  }
}
