import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgZorroAntdModule } from 'ng-zorro-antd'
import { RouterModule } from '@angular/router'
import { PipeModule } from './shared/pipes/pipes.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DirectiveModule } from './shared/directives/directive.module'
import { RadioDropdownComponent } from './shared/components/radio-dropdown/radio-dropdown.component'
import { CheckboxDropdownComponent } from './shared/components/checkbox-dropdown/checkbox-dropdown.component'
import { CheckboxDropdownTargetGroupComponent } from './shared/components/checkbox-dropdown-target-group/checkbox-dropdown-target-group.component'
import { CheckboxDropdownCompensationComponent } from './shared/components/checkbox-dropdown-compensation/checkbox-dropdown-compensation.component'

const MODULES = [CommonModule, RouterModule, NgZorroAntdModule]

@NgModule({
  declarations: [
    RadioDropdownComponent,
    CheckboxDropdownComponent,
    CheckboxDropdownTargetGroupComponent,
    CheckboxDropdownCompensationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgZorroAntdModule,
    DirectiveModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    NgZorroAntdModule,
    PipeModule,
    DirectiveModule,
    RadioDropdownComponent,
    CheckboxDropdownComponent,
    CheckboxDropdownTargetGroupComponent,
    CheckboxDropdownCompensationComponent,
  ],
})
export class SharedModule {}
