import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BasicValidator } from '../../validators/basic.validator'
interface items {
  value: number
  check: boolean
}
@Component({
  selector: 'check-box-dropdown',
  templateUrl: './checkbox-dropdown.component.html',
  styleUrls: ['./checkbox-dropdown.component.scss'],
})
export class CheckboxDropdownComponent implements OnInit {
  selectForm: FormGroup
  open: boolean = false
  handleSelected: items[] = []
  @Input() type: string
  @Input() otherTextHandle: string
  @Input() title: string
  private _listTypeOfContent: number[]
  @Input()
  set listTypeOfContent(listTypeOfContent: number[]) {
    this._listTypeOfContent = listTypeOfContent
    if (listTypeOfContent) {
      if (this.handleSelect) {
        if (this.type == 'type-of-content') {
          this.handleSelected = []
          this.listTypeOfContent.forEach(i => {
            let item = {
              value: i,
              check: this.handleSelect.includes(i) ? true : false,
            }
            this.handleSelected.push(item)
          })
          this.onLoadHandle(this.handleSelected)
        }
      }
    }
  }
  get listTypeOfContent() {
    return this._listTypeOfContent
  }
  private _handleSelect: number[]
  @Input()
  set handleSelect(handleSelect: number[]) {
    this._handleSelect = handleSelect
    this.handleSelected = []
    if (handleSelect) {
      if (this.type == 'objective') {
        for (let i = 1; i < 7; i++) {
          let item = {
            value: i,
            check: handleSelect.includes(i) ? true : false,
          }
          this.handleSelected.push(item)
        }
      } else if (this.type == 'preferred-channel') {
        for (let i = 1; i < 8; i++) {
          let item = {
            value: i,
            check: handleSelect.includes(i) ? true : false,
          }
          this.handleSelected.push(item)
        }
      } else if (this.type == 'type-of-content') {
        this.handleSelected = []
        this.listTypeOfContent.forEach(i => {
          let item = {
            value: i,
            check: handleSelect.includes(i) ? true : false,
          }
          this.handleSelected.push(item)
        })
      } else if (this.type == 'size-of-influencer') {
        for (let i = 1; i < 6; i++) {
          let item = {
            value: i,
            check: handleSelect.includes(i) ? true : false,
          }
          this.handleSelected.push(item)
        }
      }
      this.onLoadHandle(this.handleSelected)
    }
  }
  get handleSelect() {
    return this._handleSelect
  }
  @Output() dataSelect = new EventEmitter<items[]>()
  @Output() otherText = new EventEmitter<string>()
  selected: items[] = []
  status: boolean = false
  otherTextTemp: string = ''
  otherTextStatus: boolean = false
  requrieStatus: boolean = false
  constructor(private fb: FormBuilder, private readonly basicValidator: BasicValidator) {
    this.selectForm = new FormGroup({
      selected: new FormArray([]),
      otherObjective: new FormControl('', [this.basicValidator.emojiValidator(true)]),
    })
  }

  ngOnInit() {}

  onLoadHandle(selected: items[]) {
    this.selected = this.handleSelected.filter(c => c.check == true)
    this.selectedFA.clear()

    if (selected) {
      selected.forEach(el => {
        const newSelected: FormControl = new FormControl(el.check)
        this.selectedFA.push(newSelected)
      })
    }
    if (this.type == 'objective') {
      this.otherTextTemp = this.otherTextHandle
      this.otherObjectiveFC.setValue(this.otherTextHandle)
    }

    if (this.selected.length < 1) {
      this.dataSelect.emit([])
    }
    this.checkSelected()
  }

  onChange(i: number) {
    if (this.type == 'type-of-content') {
      this.selected = []
      this.selectForm.value.selected.forEach((c, index) => {
        let item: items = {
          value: this.handleSelected[index].value,
          check: c,
        }
        this.selected.push(item)
      })
      this.selected = this.selected.filter(c => c.check == true)
      this.checkSelected()
    } else {
      this.selected = []
      this.selectForm.value.selected.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        this.selected.push(item)
      })
      this.selected = this.selected.filter(c => c.check == true)
      this.checkSelected()
      if (this.type == 'objective' && this.selected.some(item => item.value == 5)) {
        this.otherTextStatus = true
        if (this.otherTextTemp) {
          this.otherTextStatus = false
        }
        this.otherObjectiveFC.setValidators([this.basicValidator.emojiValidator(true)])
        this.otherObjectiveFC.updateValueAndValidity()
      } else {
        this.otherTextStatus = false
        this.otherObjectiveFC.setValidators([])
        this.otherObjectiveFC.updateValueAndValidity()
      }
    }
  }

  onCancel() {
    if (this.handleSelected.filter(c => c.check == true).length > 0) {
      this.open = false
      this.selected = []
      this.requrieStatus = false
      if (this.type == 'type-of-content') {
        this.handleSelected.forEach((el, index) => {
          this.selectForm
            .get('selected')
            .get(`${index}`)
            .setValue(el.check)
          let item: items = {
            value: el.value,
            check: el.check,
          }
          this.selected.push(item)
        })
        this.selected = this.selected.filter(c => c.check == true)
      } else {
        this.handleSelected.forEach((el, index) => {
          this.selectForm
            .get('selected')
            .get(`${index}`)
            .setValue(el.check)
          let item: items = {
            value: index + 1,
            check: el.check,
          }
          this.selected.push(item)
        })
        this.selected = this.selected.filter(c => c.check == true)
      }
      this.otherTextTemp = this.otherTextHandle
      this.otherObjectiveFC.patchValue(this.otherTextHandle)
      if (this.type == 'objective' && this.selected.some(item => item.value == 5)) {
        this.otherTextStatus = true
        if (this.otherTextTemp) {
          this.otherTextStatus = false
        }
        this.otherObjectiveFC.setValidators([this.basicValidator.emojiValidator(true)])
        this.otherObjectiveFC.updateValueAndValidity()
      } else {
        this.otherObjectiveFC.setValidators([])
        this.otherObjectiveFC.updateValueAndValidity()
        this.otherTextStatus = false
      }
    } else {
      this.open = false
      this.otherTextStatus = false
      this.onLoadHandle(this.handleSelected)
    }
  }

  closeTag(items: items) {
    let temp = []
    this.selectForm.value.selected.forEach((c, index) => {
      let item: items = {
        value: index + 1,
        check: c,
      }
      temp.push(item)
    })

    temp = temp.filter(c => c.check == true)
    if (temp.length >= 1) {
      if (this.type == 'type-of-content') {
        const isLargeNumber = element => element.value == items.value
        let index = this.handleSelected.findIndex(isLargeNumber)
        this.selectForm
          .get('selected')
          .get(`${index}`)
          .setValue(!items.check)
        this.selected = []
        this.selectForm.value.selected.forEach((c, index) => {
          let item: items = {
            value: this.handleSelected[index].value,
            check: c,
          }
          this.selected.push(item)
        })
        this.selected = this.selected.filter(c => c.check == true)
        this.checkSelected()
      } else {
        this.selectForm
          .get('selected')
          .get(`${items.value - 1}`)
          .setValue(!items.check)
        this.selected = []
        this.selectForm.value.selected.forEach((c, index) => {
          let item: items = {
            value: index + 1,
            check: c,
          }
          this.selected.push(item)
        })
        this.selected = this.selected.filter(c => c.check == true)
        this.checkSelected()
        if (this.type == 'objective' && this.selected.some(item => item.value == 5)) {
          this.otherTextStatus = true
          if (this.otherTextTemp) {
            this.otherTextStatus = false
          }
          this.otherObjectiveFC.setValidators([this.basicValidator.emojiValidator(true)])
          this.otherObjectiveFC.updateValueAndValidity()
        } else {
          this.otherObjectiveFC.setValidators([])
          this.otherObjectiveFC.updateValueAndValidity()
          this.otherTextStatus = false
        }
      }
    }
  }

  openSelectItem() {
    this.open = true
  }

  dataChanged(value) {
    this.otherTextTemp = value
    this.otherObjectiveFC.patchValue(value)
    if (
      this.otherTextTemp == '' &&
      this.selected.some(item => item.value == 5) &&
      this.type == 'objective'
    ) {
      this.otherTextStatus = true
    } else {
      this.otherTextStatus = false
    }
  }

  checkSelected() {
    if (this.selected.length == 0) {
      this.requrieStatus = true
    } else {
      this.requrieStatus = false
    }
  }

  onSubmit() {
    this.open = false
    this.selected = []
    const dataSelected = []
    if (this.type == 'type-of-content') {
      this.selectForm.value.selected.forEach((c, index) => {
        let item: items = {
          value: this.handleSelected[index].value,
          check: c,
        }
        this.selected.push(item)
      })
      this.selected = this.selected.filter(c => c.check == true)
      this.selected.forEach(item => {
        dataSelected.push(item.value)
      })
      this.dataSelect.emit(dataSelected)
    } else {
      this.selectForm.value.selected.forEach((c, index) => {
        let item: items = {
          value: index + 1,
          check: c,
        }
        this.selected.push(item)
      })
      this.selected = this.selected.filter(c => c.check == true)
      this.selected.forEach(item => {
        dataSelected.push(item.value)
      })
      this.dataSelect.emit(dataSelected)

      if (
        this.otherTextTemp != '' &&
        this.selected.some(item => item.value == 5) &&
        this.type == 'objective'
      ) {
        this.otherText.emit(this.otherTextTemp)
      }
    }
  }

  get selectedFA(): FormArray {
    return this.selectForm.get('selected') as FormArray
  }

  get otherObjectiveFC(): FormControl {
    return this.selectForm.get('otherObjective') as FormControl
  }
}
