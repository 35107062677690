import { Component } from '@angular/core'
import { User } from 'src/app/models/user.model'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'cui-topbar-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class TopbarProfileMenuComponent {
  user: User
  userName: string
  role: string

  constructor(public authService: AuthService) {
    const userInfo = JSON.parse(localStorage.getItem('user'))
    this.user = userInfo
    if (!userInfo || this.user.userId == -1) {
      this.authService.getCurrentProfile().subscribe(u => {
        this.user = u
        localStorage.setItem('user', JSON.stringify(u))
      })
    }
    this.userName = this.user.username || 'Anonymous'
    this.role = this.user.role || 'admin'
  }

  logout() {
    this.authService.SignOut()
  }
}
