import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'

import { Observable, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import { catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd'
import { AuthService } from 'src/app/services/auth.service'

@Injectable()
export class HttpCredentialInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private notification: NzNotificationService,
    public authService: AuthService,
  ) { }

  intercept(originalReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = this.attachCredential(originalReq)
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.SignOut()
        } else if (error.status === 403) {
          this.notification.error('Not enough permission', error.message)
        } else if (error.status === 404) {
          this.notification.error('Not Found', error.message)
        } else if (Array.from(error.status.toString())[0] === '5') {
          this.authService.SignOut()
        }
        return throwError(error)
      })
    )
  }

  attachCredential(req: HttpRequest<any>) {
    if (req.url.indexOf(environment.api_url) === 0) { return req.clone({ withCredentials: true }) } else { return req }
  }
}
