import { Pipe, PipeTransform } from '@angular/core'
import { formatNumber } from '@angular/common'

@Pipe({ name: 'formatPrice' })
export class FormatPricePipe implements PipeTransform {
  transform(value: number): string {
    if (value > 8000000) {
      return 'ติดต่อร้านค้า'
    }

    if (value <= 0.1) {
      return 'ไม่ระบุราคา'
    }
    return formatNumber(value, 'en-US', '0.0-2')
  }
}
